<template>
  <div class="wrapper">
    <menu-ver :type="typeMenu" :name="typeMenu" />
    <div class="container m-3">
      <div class="table-header">
        <button
          class="btn button buttonCreate"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
          @click="getActions(0)"
        >
          <i class="fas fa-plus-circle"></i> {{ $t("actions.create") }}
        </button>
      </div>
      <table class="table" v-if="teachers && teachers.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("profesor.name") }}</th>
            <th scope="col">{{ $t("profesor.email") }}</th>
            <th scope="col">{{ $t("profesor.phone") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in teachers" :key="item.prof_id">
            <td>{{ item.prof_nombre }}</td>
            <td>{{ item.prof_email }}</td>
            <td>{{ item.prof_telefono }}</td>
            <td style="min-width: 90pt">
              <button
                class="btn button buttonDelete ms-2"
                @click="getActions(2, item)"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
                :data-title="$t(`menu.borrar`)"
              >
                <i class="fas fa-trash"></i>
              </button>
              <button
                class="btn button buttonEdit"
                @click="getActions(1, item)"
                data-bs-toggle="modal"
                data-bs-target="#editModal"
                :data-title="$t(`menu.editar`)"
              >
                <i class="fas fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ $t("errors.3") }}</p>
    </div>
  </div>

  <!-- Modal add -->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("profesor.addTitle", { name: `${teacherId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{ $t(`profesor.name`) + " *" }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prof_nombre"
                :placeholder="$t(`profesor.name`) + ' *'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`profesor.email`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="email"
                class="form-control"
                v-model.trim="prof_email"
                :placeholder="$t(`profesor.email`) + ' *'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.superusers.password`) + " *"
              }}</label>
              <input
                v-if="visPas"
                type="password"
                class="form-control"
                v-model.trim="prof_clave"
                :placeholder="$t(`maintenance.superusers.password`) + '*'"
                maxlength="50"
              />
              <input
                v-else
                type="text"
                class="form-control"
                v-model.trim="prof_clave"
                :placeholder="$t(`maintenance.superusers.password`) + '*'"
                maxlength="50"
              />
              <i
                v-if="!visPas"
                @click="visPas = true"
                class="fas fa-eye-slash field-icon"
              ></i>
              <i
                v-else
                @click="visPas = false"
                class="fas fa-eye field-icon"
              ></i>
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`profesor.nac_date`) }}</label>
              <input
                style="text-transform: uppercase"
                type="date"
                class="form-control"
                v-model.trim="prof_fecha_nacimiento"
                placeholder="dd/mm/yyyy"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`profesor.prefix`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prof_prefijo"
                :placeholder="$t(`profesor.prefix`)"
                maxlength="5"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`profesor.phone`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prof_telefono"
                :placeholder="$t(`profesor.phone`)"
                maxlength="12"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`profesor.iban`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prof_iban"
                :placeholder="$t(`profesor.iban`)"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`profesor.lang`) }}</label>
              <select
                style="height: 35pt; margin-bottom: 40px"
                class="form-select inputForm"
                aria-label="Elija una opción"
                v-model.trim="prof_idioma"
              >
                <option value="ESP">{{ $t(`languages.es`) }}</option>
                <option value="ENG">{{ $t(`languages.en`) }}</option>
              </select>
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`profesor.dni`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prof_dni"
                :placeholder="$t(`profesor.dni`)"
                maxlength="255"
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ messageError }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="addTeacher"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal delete -->
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("profesor.deleteTitle", { name: `${teacherId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ $t("profesor.deleteMessage") }}
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            id="btnDelete"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="deleteTeacher"
          >
            {{ $t("maintenance.actions.confirm") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal edit -->
  <div
    class="modal fade"
    id="editModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("profesor.editTitle", { name: `${teacherId}` }) }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForms()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label">{{ $t(`profesor.name`) + " *" }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prof_nombre"
                :placeholder="$t(`profesor.name`) + ' *'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`profesor.email`) + " *"
              }}</label>
              <input
                style="text-transform: uppercase"
                type="email"
                class="form-control"
                v-model.trim="prof_email"
                :placeholder="$t(`profesor.email`) + ' *'"
                maxlength="255"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`maintenance.superusers.password`) + " *"
              }}</label>
              <input
                v-if="visPas"
                type="password"
                class="form-control"
                v-model.trim="prof_clave"
                :placeholder="$t(`maintenance.superusers.password`) + '*'"
                maxlength="50"
              />
              <input
                v-else
                type="text"
                class="form-control"
                v-model.trim="prof_clave"
                :placeholder="$t(`maintenance.superusers.password`) + '*'"
                maxlength="50"
              />
              <i
                v-if="!visPas"
                @click="visPas = true"
                class="fas fa-eye-slash field-icon"
              ></i>
              <i
                v-else
                @click="visPas = false"
                class="fas fa-eye field-icon"
              ></i>
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`profesor.nac_date`) }}</label>
              <input
                style="text-transform: uppercase"
                type="date"
                class="form-control"
                v-model.trim="prof_fecha_nacimiento"
                placeholder="dd/mm/yyyy"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`profesor.prefix`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prof_prefijo"
                :placeholder="$t(`profesor.prefix`)"
                maxlength="5"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`profesor.phone`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prof_telefono"
                :placeholder="$t(`profesor.phone`)"
                maxlength="12"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`profesor.iban`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prof_iban"
                :placeholder="$t(`profesor.iban`)"
                maxlength="50"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`profesor.lang`) }}</label>
              <select
                style="height: 35pt; margin-bottom: 40px"
                class="form-select inputForm"
                aria-label="Elija una opción"
                v-model.trim="prof_idioma"
              >
                <option value="ESP">{{ $t(`languages.es`) }}</option>
                <option value="ENG">{{ $t(`languages.en`) }}</option>
              </select>
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`profesor.dni`) }}</label>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="prof_dni"
                :placeholder="$t(`profesor.dni`)"
                maxlength="12"
              />
            </div>
            <span
              v-if="formError"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ messageError }}</span
            >
            <span v-else style="float: right; font-size: 12px">{{
              $t("errors.comment")
            }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="editTeacher"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
              <div class="modal-body" v-else-if="action === 2">
                {{ $t("errors.6") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0' || action === 2"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForms()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="action === 0 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#addModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="action === 1 && result !== '0'"
            class="btn button btn-return-result"
            data-bs-target="#editModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      teachers: [],
      teacher: {},
      prof_id: "",
      prof_nombre: "",
      prof_email: "",
      prof_clave: "",
      prof_fecha_nacimiento: "",
      prof_prefijo: "",
      prof_telefono: "",
      prof_iban: "",
      prof_idioma: "ESP",
      prof_dni: "",
      teacherId: "",
      result: "",
      action: 0,
      id: "",
      formError: false,
      loading: false,
      visPas: true,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      messageError: "",
      typeMenu: "",
    };
  },
  created() {
    this.getTeachers();
    this.changeBreadCrumb(25);
    this.bigDrop();
    if (window.location.href.indexOf("adminsu") > 0) {
      this.typeMenu = "superuser";
    } else {
      this.typeMenu = "company";
    }
  },
  computed: {
    ...mapState(["servidor"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getTeachers() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=profesor&ac=listado`
        );
        if (res.data) {
          this.teachers = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async addTeacher() {
      if (this.validateForm()) {
        try {
          this.loading = true;
          // console.log("fecha", this.prof_fecha_nacimiento);
          const auxDate = this.prof_fecha_nacimiento.split("-");
          const date = auxDate[2] + "/" + auxDate[1] + "/" + auxDate[0];
          const teacher = {
            prof_nombre: this.prof_nombre.toUpperCase(),
            prof_email: this.prof_email.toUpperCase(),
            prof_clave: this.prof_clave,
            prof_fecha_nacimiento: date,
            prof_prefijo: this.prof_prefijo,
            prof_telefono: this.prof_telefono.toUpperCase(),
            prof_iban: this.prof_iban.toUpperCase(),
            prof_idioma: this.prof_idioma.toUpperCase(),
            prof_dni: this.prof_dni,
          };
          // console.log("teach", teacher);
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=profesor&ac=grabar`,
            teacher
          );
          this.result = res.data.RESULT;
          $("#addModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
        }
        this.loading = false;
      }
    },
    async editTeacher() {
      if (this.validateForm()) {
        try {
          this.loading = true;
          // console.log("fecha", this.prof_fecha_nacimiento);
          const auxDate = this.prof_fecha_nacimiento.split("-");
          const date = auxDate[2] + "/" + auxDate[1] + "/" + auxDate[0];
          const teacher = {
            prof_id: this.prof_id,
            prof_nombre: this.prof_nombre.toUpperCase(),
            prof_email: this.prof_email.toUpperCase(),
            prof_clave: this.prof_clave,
            prof_fecha_nacimiento: date,
            prof_prefijo: this.prof_prefijo,
            prof_telefono: this.prof_telefono.toUpperCase(),
            prof_iban: this.prof_iban.toUpperCase(),
            prof_idioma: this.prof_idioma.toUpperCase(),
            prof_dni: this.prof_dni,
          };
          // console.log("teacherEdit", teacher);
          const res = await axios.post(
            `${this.servidor}api/op.asp?tp=profesor&ac=actualizar`,
            teacher
          );
          this.result = res.data.RESULT;
          $("#editModal").modal("hide");
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
        }
        this.loading = false;
      }
    },
    async deleteTeacher() {
      try {
        const borrado = {
          prof_id: this.id,
        };
        // console.log(borrado);
        this.loading = true;
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=profesor&ac=borrar`,
          borrado
        );
        this.result = res.data.RESULT;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    getId(event) {
      this.teacherId = event[0];
    },
    validateForm() {
      if (this.prof_nombre === "") {
        this.formError = true;
        this.messageError = this.$t("errors.fieldOb");
        return false;
      }
      if (!this.isEmailValid()) {
        this.formError = true;
        this.messageError = this.$t("errors.fieldOb");
        return false;
      }
      if (!this.validatePassword()) {
        this.formError = true;
        this.messageError = this.$t("configuration.messageError2");
        return false;
      }
      return true;
    },
    cleanForms() {
      this.prof_id = "";
      this.prof_nombre = "";
      this.prof_clave = "";
      this.prof_fecha_nacimiento = "";
      this.prof_email = "";
      this.prof_prefijo = "";
      this.prof_telefono = "";
      this.prof_iban = "";
      this.prof_idioma = "ESP";
      this.prof_dni = "";
      this.loading = false;
      this.formError = false;
      this.messageError = "";
      this.getTeachers();
    },
    getActions(act, item) {
      this.action = act;
      if (act === 0) {
        return;
      }
      if (act === 1 && item) {
        // console.log("teacher", item);
        this.teacher = item;
        this.prof_id = item.prof_id;
        this.prof_nombre = item.prof_nombre;
        this.prof_clave = item.prof_clave;
        this.prof_email = item.prof_email;
        this.prof_prefijo = item.prof_prefijo;
        this.prof_telefono = item.prof_telefono;
        this.prof_iban = item.prof_iban;
        this.prof_idioma = item.prof_idioma;
        this.prof_dni = item.prof_dni;
        if (item.prof_fecha_nacimiento) {
          const auxDate = item.prof_fecha_nacimiento.split("T");
          const date = auxDate[0];
          this.prof_fecha_nacimiento = date;
        }
        return;
      }
      // console.log("prof", item);
      if (item.prof_id) {
        this.id = item.prof_id;
      }
    },
    isEmailValid() {
      return this.prof_email == ""
        ? ""
        : this.reg.test(this.prof_email)
        ? true
        : false;
    },
    validatePassword() {
      if (this.prof_clave.length >= 8) {
        let mayuscula = false;
        let minuscula = false;
        let numero = false;
        let caracter_raro = false;

        for (var i = 0; i < this.prof_clave.length; i++) {
          if (
            this.prof_clave.charCodeAt(i) >= 65 &&
            this.prof_clave.charCodeAt(i) <= 90
          ) {
            mayuscula = true;
          } else if (
            this.prof_clave.charCodeAt(i) >= 97 &&
            this.prof_clave.charCodeAt(i) <= 122
          ) {
            minuscula = true;
          } else if (
            this.prof_clave.charCodeAt(i) >= 48 &&
            this.prof_clave.charCodeAt(i) <= 57
          ) {
            numero = true;
          } else {
            caracter_raro = true;
          }
        }
        if (
          mayuscula == true &&
          minuscula == true &&
          caracter_raro == true &&
          numero == true
        ) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}
.btn {
  float: right;
}
.field-icon {
  float: right;
  margin-top: -71px;
  position: relative;
  z-index: 2;
  left: -11px;
  cursor: pointer;
}
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #333;
  color: #fff;
  font-size: 14px;
  position: absolute;
  padding: 10px 20px;
  bottom: 2em;
  left: -5em;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  border-radius: 6px;
}

[data-title] {
  position: relative;
}
</style>